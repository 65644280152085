<script lang="ts">
export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Collapse',
    props: {
        showEsBrand: {
            type: Boolean,
            default: false,
            required: false,
        },
        heading: {
            type: String,
            required: true,
        },
    },
});
</script>

<template>
    <div class="Collapse">
        <es-collapse
            id="collapse"
            :border="false"
            class="px-lg-0"
            :class="[
                showEsBrand
                    ? 'Collapse-branded rounded bg-white px-50 pb-lg-0'
                    : 'bg-transparent text-gray pb-50 mb-n200 mb-lg-0',
            ]">
            <template #title>
                <div class="d-flex align-items-center">
                    <icon-es-leaf
                        v-if="showEsBrand"
                        class="text-orange-900 mr-100 mr-lg-50" />
                    <div class="mb-0 font-size-lg-100 text-gray-900 font-weight-bold">
                        {{ heading }}
                    </div>
                </div>
            </template>
            <div :class="showEsBrand ? 'mb-0 pb-25 px-25' : 'pt-50'">
                <slot name="content" />
            </div>
        </es-collapse>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';

.Collapse {
    &-branded {
        border: 1px solid variables.$orange-900;
        @include breakpoints.media-breakpoint-up(lg) {
            background: transparent !important;
            border: 0;
        }

        :deep(button) {
            color: variables.$orange-900 !important;
            padding-bottom: 0 !important;
            @include breakpoints.media-breakpoint-up(lg) {
                color: variables.$gray !important;
            }
        }
    }

    :deep(.btn svg) {
        /* override EsCollapse forcing the icon to be 30px */
        height: 24px !important;
        width: 24px !important;
    }
}
</style>
